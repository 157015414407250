import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import usePost from "../hooks/usePost";
import {CSSTransition, TransitionGroup} from "react-transition-group";

import FrontpageMainContent from "./layout/FrontpageMainContent";
import FrontpageSportContent from "./layout/FrontpageSportContent";
import DefaultContent from "./layout/DefaultContent";
import ExerciseContent from "./layout/ExerciseContent";
import ActivityContent from "./layout/ActivityContent";
import ChildPages from "./ChildPages";
import CookieInformationTemplate from "./parts/CookieInformationTemplate";
import LoadingAnimation from "./parts/LoadingAnimation";
import TitleBar from "./TitleBar";
import MetaTags from "./content/MetaTags";

const PageLayout = ({onPageChanged = null}) => {

	const { isLoading, error, data } = usePost();
	const location = useLocation();

	const cssTransitionClassName = ( location.state && location.state.useBack ? 'next' : 'next' );

	useEffect(() => {

		if(data && onPageChanged) {
			onPageChanged(data.context.blogId, data.context.template);
		}

	}, [data, onPageChanged]);

	if(isLoading || error) {
		return (
			<TransitionGroup component={null}>
				<CSSTransition
					key={location.pathname}
					classNames={cssTransitionClassName}
					timeout={500}
				>
					<main id="page-layout-loading" className="page-layout">
						<div id="title-bar"><div className="title-bar-inner" /></div>
						<div className="the-editor-content">
							{ error ? 'An error has occurred: ' + error.message : null }
							<LoadingAnimation />
						</div>
					</main>
				</CSSTransition>
			</TransitionGroup>
		);
	}

	const renderContent = () => {

		const context = data.context;
		const content = data.content;

		switch(context.type) {
			case 'activity':
				return <ActivityContent key={ context.guid } data={ content } />;

			case 'exercise':
				return <ExerciseContent key={ context.guid } data={ content } />;

			case 'post':
			case 'page':
			default:

				if(context.template === 'page_frontpage_main') {
					return <FrontpageMainContent key={ context.guid } data={ content } />
				}
				else if(context.template === 'page_frontpage_sport') {
					return <FrontpageSportContent key={ context.guid } data={ content } />
				}
				else if(context.id === 823) {
					return <>
						<DefaultContent key={ context.guid } data={ content } />
						<CookieInformationTemplate />
					</>;
				}

				return <DefaultContent key={ context.guid } data={ content } />;
		}
	}

	const id = `page-layout-${data.context.blogId}-${data.context.id}`;
	const className = `page-layout page-type-${data.context.type} color-theme-${data.context.colorTheme} ${ !data.titleBar ? 'no-title-bar' : '' } ${data.context.template}`;

	return (
		<TransitionGroup component={null}>
			<CSSTransition
				key={location.pathname}
				classNames={cssTransitionClassName}
				timeout={500}
			>
				<main id={id} className={className}>
						<MetaTags tags={data.metaTags} />
						<div className="page-layout-inner">
							{
								data.titleBar
									? <TitleBar data={data.titleBar} />
									: null
							}

							<div className="the-editor-content">
								{ renderContent() }
							</div>

							{
								data.childPages
									? <ChildPages data={ data.childPages } heading={ data.content.heading_child_pages } />
									: null
							}
						</div>

				</main>
			</CSSTransition>
		</TransitionGroup>
	);
};

export default PageLayout;